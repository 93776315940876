:root {

	// **********************************************************
	// COLORS
	@each $color, $value in $theme-colors {
		--#{$prefix}#{$color}: 		#{$value};
	}
	@each $color, $value in $theme-colors-rgb {
		--#{$prefix}#{$color}-rgb: 	#{$value};
	}
	
	// base Colors
	--#{$prefix}body-color:	  			#{$body-color};
	--#{$prefix}body-color-rgb: 		#{to-rgb($body-color)};
	--#{$prefix}body-bg: 						#{$body-bg};
	--#{$prefix}body-bg-rgb: 				#{to-rgb($body-color)};
	--#{$prefix}link-color:					#{$background01};
	--#{$prefix}link-hover-color:   #{$link-hover-color};
	--#{$prefix}white-text:					#{$white}; 

	// xxss
	--#{$prefix}facebook:        		#4267B2;
	--#{$prefix}twitter:         		#111111;
	--#{$prefix}whatsapp:        		#128C7E;
	--#{$prefix}linkedin:        		#0077B5; 

	// nav
	--#{$prefix}hamburger-color:		var(--#{$prefix}white);
	--#{$prefix}nav-link-color:			var(--#{$prefix}primary);
	--#{$prefix}nav-link-hover-color: var(--#{$prefix}dark);
	--#{$prefix}bgMenu:							var(--#{$prefix}white);

	// categories
	--#{$prefix}type-bg:					#A8514C;
	--#{$prefix}type-color:					#{$white};

	// header
	--#{$prefix}bgHeader:						#{$white};
	--#{$prefix}header-text-color1: #{$white};
	--#{$prefix}header-text-color2: #{$secondary};

	// carousel
	--#{$prefix}dot-color: 					#{$gray-300};
	--#{$prefix}dot-selected-color: #{$secondary};

	// direct actions (floating buttons bar)
	--#{$prefix}directActions-color: var(--#{$prefix}white-text);
	--#{$prefix}directActions-bg: var(--#{$prefix}background-01);

	// il·lustracions footer
	--#{$prefix}green-illustration: var(--#{$prefix}background-03);
	--#{$prefix}gray-illustration: #798576;
	--#{$prefix}black-illustration: #152726; 
	--#{$prefix}gray2-illustration: #f8f8f8;
	--#{$prefix}gray3-illustration: #6a7567;

	// forms
	--#{$prefix}error-color: #c92121;

	// Companies
	--#{$prefix}gestiona: 			#{$gestiona};
	--#{$prefix}drag:          		#{$drag};
	
	// **********************************************************
	// SIZES
	--#{$prefix}body-font-size:		#{$font-size-base};
	
	// Alçada Tabs
	--#{$prefix}tab-height-min:		19rem;
	--#{$prefix}tab-height-max: 	190rem;
	
	// **********************************************************
	// BUTTONS
	--#{$prefix}btn-font-size: 		#{$btn-font-size};
	

	// **********************************************************
	// BORDERS
    // scss-docs-start root-border-var
	--#{$prefix}border-width: 		#{$border-width};
	--#{$prefix}border-style: 		#{$border-style};
	--#{$prefix}border-color: 		#{$border-color};
	--#{$prefix}border-color-translucent: #{$border-color-translucent};

	--#{$prefix}border-radius: 		#{$border-radius};
	--#{$prefix}border-radius-sm: 	#{$border-radius-sm};
	--#{$prefix}border-radius-lg: 	#{$border-radius-lg};
	--#{$prefix}border-radius-xl: 	#{$border-radius-xl};
	--#{$prefix}border-radius-2xl:	#{$border-radius-2xl};
	--#{$prefix}border-radius-pill: 	#{$border-radius-pill};
	// scss-docs-end root-border-var


	// **********************************************************
	// ICONS
	--#{$prefix}greenIcon-bg: var(--#{$prefix}gray-100, #{$gray-100});
  --#{$prefix}greenIcon-color: var(--#{$prefix}secondary, #{$secondary});
  --#{$prefix}greenIcon-fs: 2rem;
  --#{$prefix}greenIcon-radius: .5rem;
  --#{$prefix}greenIcon-height: 3.5rem;


	// **********************************************************
	// SLIDE EMBLA
	--slide-spacing: 1rem;
	--slide-size: 100%;
	--slide-height: 19rem; 
	

	// **********************************************************
	// SLIDE CLIENTS
	--#{$prefix}logo-number: 8;
 
}
  