// **********************************************************
// MAIN NAVIGATION
.mnPrincipal { 
  --#{$prefix}greenIcon-fs: #{$font-size-base};
  --#{$prefix}greenIcon-radius: var(--ecc-border-radius-sm);
  --#{$prefix}greenIcon-height: 2.5rem;

	margin-left: auto;
	margin-right: 1rem;

	// **********************************************************
	// NAVIGATION TOGGLE
	.hamburger-wrapper {
		width: auto;
		height: auto;
		padding: 0;
		margin: 0;
		.hamburger {
			width: 3rem;
			height: 3rem;
			&::before, &::after {
				height: .25rem;
				background-color: var(--#{$prefix}background-01);
				border-radius: 2px;
				width: 1.5rem;
				left: 50%;
				translate: -50%;
			}
			&::before {
				top: auto;
				bottom: .85rem;
			}
			&::after {
				top: .85rem;
			}
			.hamburger-inner { 
				width: 1.5rem;
				top: 50%;
				left: 50%;
				translate: -50% -0.125rem;
				&::after, &::before {
					background-color: var(--#{$prefix}background-01);
					border-radius: 2px;
				}
			}
			&.is-active {
				&::after, &::before {
					opacity: 0;
				}
			}
		}
	}

	&.navigation {
		ul.ui.secondary.pointing.menu {
			list-style-type: none;
			padding-left: 0;
		}
		.ui.secondary.pointing.menu .item {
			text-transform: none;
			padding-top: 0.5em;
			&.current, &.active {
				border-color: var(--#{$prefix}secondary);
				color: var(--#{$prefix}body-color);
			}
		}
		&.ui.menu { 
			.item {
				color: var(--#{$prefix}body-color);
			}
		}
	}

	// **********************************************************
	// DESKTOP NAVIGATION

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		margin: 0;
		.ui.menu.secondary.large.pointing {			
			font-size: $font-size-base;
			min-height: 0;
			> .navItem > .item {
				display: inline-block;
				padding-bottom: 0.5em !important;
				border-top: 4px solid transparent;
			}
			.mnPrincipal__second {
				padding-left: 0.75rem;
				padding-right: 0.75rem;
				& > .item {
					padding: 2rem .25rem !important; 
					align-self: stretch;
					&:hover, &:focus {
						background-color: transparent !important;
					}
				}
			}
			.ui.inline.dropdown{				
				& > .text {
					font-weight: $font-weight-normal;
				}
			}
			.navItem {
				& + .navItem { 
					&::before {
						content: '';
						display: inline-flex !important;
						height: 0.5em;
						width: 0.5em;
						border-radius: 50%;
						margin-left: calc(2rem - .25em);
						margin-right: calc(2rem - .25em);
						background-color: var(--#{$prefix}body-color);						
					}
				}
			}
			.item {
				margin-right: 0;
				color: var(--#{$prefix}body-color);
				position: static;
				a.item {
					font-weight: $font-weight-bold;
					font-size: $h5-font-size;
					padding: 1.5rem 1rem;
					text-wrap: balance;
					white-space: normal;
					display: flex;
					align-items: flex-start;
					background-color: var(--#{$prefix}gray-100);
					border-radius: var(--#{$prefix}border-radius-sm);
					*[class^='fa'], *[class*=' fa']{
						color: var(--#{$prefix}secondary);
						font-size: 3rem;
						margin-right: 1rem;
					}
					&:hover, &:focus {
						background-color: var(--#{$prefix}gray-100);
						color: var(--#{$prefix}secondary);		
					}
					&.item--company {
						background-color: var(--#{$prefix}company-menu-color, var(--#{$prefix}secondary));
						color: var(--#{$prefix}white-text);
						gap: 1.5rem;
						align-items: center;
						font-size: calc($h6-font-size * 1);
						letter-spacing: -0.5px;
						padding: 1.5rem .5rem;
						height: 100%;
						img {
							flex: 0 0 auto;
							width: auto;
							height: 2.25rem;
						}
						&__title {
							min-height: 2.5em;
						}
						&.active {
							border-color:transparent;
						}
						&:hover, &:focus {
							background-color: var(--#{$prefix}primary);
						}
					}
				}
				&:hover, &:focus {
					color: var(--#{$prefix}link-hover-color);
					.divider.text::after {
						opacity: .66;
						transform: translate(-50%, 0);
					}
				}
				i.dropdown.icon {
					display: none;
				}
				.divider.text {
					position: relative;
					&::after {
						transition: $transition-base;
						font-family: 'Font Awesome 6 Free';
						content: "\f078";
						font-size: .66em;
						position: absolute;
						top: calc(100% + .125em);
						left: 50%;
						transform: translate(-50%, -0.25rem);
						opacity: 0;
					}
				}
			}
		}
		.ui.dropdown .menu.visible.transition {
			display: flex !important;
			flex-direction: row !important;
			body.bigFont & {
				flex-wrap: wrap;
			}
			& > * {
				flex: 1 1 0;
			}
		}
		.ui.menu.secondary.large.pointing .item.active .divider.text::after {
			opacity: 0 !important;
		}
	}
	
	// **********************************************************
	// MOBILE NAVIGATION
	.mobile-menu-nav {
		.ui.pointing.secondary.stackable.menu {
			align-items: flex-start;
			flex-direction: column;
			.item {
				flex-wrap: wrap;
				svg {
					color: var(--#{$prefix}secondary);
					margin-right: .5rem;
				}
			}
		}
		.ui.inline.dropdown > .text {
			font-weight: 400;
		}
		.ui.menu .item {
			color: var(--ecc-body-color);
		}
		.ui.pointing.secondary.stackable.menu .mnPrincipal__second {
			font-size: $font-size-base;
		}
		.ui.dropdown .menu{
			position: relative;
			flex: 0 0 100%;
			box-shadow: none;
			border: none;
			& > * {
			white-space: normal;
			}
			.item {
				padding: 0 !important;
				.item {
					padding: 0.5em !important;
					text-align: center;
				}
			}
		}
	}

	// **********************************************************
	// GREEN ICON
	.greenIcon {
		display: flex;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
		margin-bottom: 1rem;
	}
}