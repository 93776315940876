// **********************************************************
// LAYOUT
.companyCards {
    --#{$prefix}companyCardColor: var(--#{$prefix}white);
    padding-bottom: 3rem;
    .ui.grid > .row {
        gap: 2rem 0;
        align-items: stretch;
    }
}

// **********************************************************
// ITEM
.companyCardsItem {
    &, &.ui.card {
        transition: $transition-base;
        box-shadow: none;
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}companyCardColor);
        border-radius: var(--#{$prefix}border-radius-xl);
        align-items: flex-start;
        &:hover, &:focus-within {
            background-color: var(--#{$prefix}primary) !important;
        }
    }
    &--ecity {
        background-color: var(--#{$prefix}secondary) !important;
    }    
    &--gestiona {
        background-color: var(--#{$prefix}gestiona) !important;
    }
    &--drag {
        background-color: var(--#{$prefix}drag) !important;
    }

    &__icon {
        margin: 2rem auto 1rem 1rem;
        height: 4rem;
        width: auto;
        display: block;
        text-align: left;
        body.bigFont & {
            height: auto;
            max-width: 100%;
        }
    }

    &.ui.card > .content {
        border-top: none;
    }

    &.ui.card > .content > &__title.header {
        font-size: $h4-font-size  !important;
        font-weight: $font-weight-bold;
    }
    &__link {
        color: var(--#{$prefix}companyCardColor);
        text-decoration: none;
        min-height: 2.5em;
        display: block;
        &:hover, &:focus {
            text-decoration: none;
            color: var(--#{$prefix}companyCardColor);
        }
    }
}